
import { defineComponent, ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import PolicyService from "@/core/services/car/PolicyService";
import PolicyAction from "@/components/dropdown/car/PolicyAction.vue";
import UserService from "@/core/services/UserService";
// import Multiselect from "@vueform/multiselect";
import LocalStorageServices from "@/core/services/LocalStorageServices";
import TransferSaleModal from "@/components/modals/forms/TransferSaleModal.vue";
import TransferPolicyModal from "@/components/modals/forms/TransferPolicyModal.vue";
import CancelPolicyReqModal from "@/components/modals/forms/CancelPolicyModal.vue";
import PolicyCancelRefundAction from "@/components/dropdown/car/PolicyCancelRefundAction.vue";
import EditCancelRequestModal from "@/components/modals/forms/EditCancelRequestModal.vue";
import RefundPolicyRequestModal from "@/components/modals/forms/RefundRequestModal.vue";
import PolicyDetailsModal from "@/components/modals/forms/PolicyDetailsModal.vue";
import PolicyDocumentModal from "@/components/modals/forms/PolicyDocumentModal.vue";
import ViewAllDocumentsModal from "@/components/modals/forms/ViewAllDocumentsModal.vue";
import EditCancelApprovalModal from "@/components/modals/forms/EditCancelApprovalModal.vue";
import refundModal from '@/components/modals/forms/refundModal.vue';
import MasterService from "@/core/services/car/MasterService";
import mainSocket from "./../../../main-socket";
import { Mutations } from "@/store/enums/StoreEnums";
import ViewTempDocumentModal from "@/components/modals/forms/TempdocumentModal.vue";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import { ElNotification } from "element-plus";
import { customFunction } from "@/store/stateless/store";
import moment from "moment";
import { boolean } from "yup";
import { setPolicyClaim } from '@/store/composable/Policy'

interface PolicyBodyParam {
  per_page: string;
  status: any;
  keyword: string;
  start_date: string | null;
  end_date: string | null;
  agent: string;
  policy_sales_agent: any;
  underwriter: any;
  payment_type: any;
  task_due_dates: any;
  index: number;
  type: string;
}

export default defineComponent({
  name: "list-policy",
  components: {
    PolicyAction,
    // Multiselect,
    TransferSaleModal,
    TransferPolicyModal,
    CancelPolicyReqModal,
    EditCancelRequestModal,
    RefundPolicyRequestModal,
    PolicyCancelRefundAction,
    ViewTempDocumentModal,
    PolicyDocumentModal,
    PolicyDetailsModal,
    ViewAllDocumentsModal,
    EditCancelApprovalModal,
    refundModal,
  },
  props: {
    status: {
      type: Number || String,
      required: true
    },
    workFlow: String,
    type: {
      type: String,
      required: false,
      default: ''
    },
    isViewRefund: {
      type: Boolean,
      required: false,
      default: false
    },
    isqca: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      shortcuts: [
        {
          text: "Last week",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          })(),
        },
        {
          text: "Last month",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          })(),
        },
        {
          text: "Last 3 months",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          })(),
        },
      ],
      value1: "",
      // value2: "",
      PolicyStatus: ["-", "Pending", "Completed", "Failed"],
    };
  },
  setup(props) {
    const store = useStore();
    let exportUrl = ref<string>("");
    let policyDetails = ref<any>();
    let tempDocs = ref<any>("");

    let cancellationDocs = ref<any>("");
    let categories = ref<any>("");
    let paymentTypes = ref<any>();
    let isViewCancellation = ref<any>(false);
    const backDate = ref(moment().subtract(3, 'months').format('YYYY-MM-DD'))
    const presentDate = ref(moment().format('YYYY-MM-DD'))

    const router = useRouter()

    let bodyParameters = ref({
      status: props.status,
      per_page: "50",
      keyword: "",
      start_date: "",
      end_date: "",
      agent: "all",
      policy_sales_agent: "",
      underwriter: "",
      payment_type: "" as any,
      task_due_dates: "",
      index: 1,
      type: props.type,
    });
    // let agentList = ref({
    //   data: [],
    //   options: store.getters.getDomSalesAgent,
    //   placeholder: "Select Policy Sales Agent",
    //   searchable: true,
    //   createTag: true
    // });
    let agentList = computed(() => {
      return store.getters.getDomSalesAgent;
    });
    let underWriterList = computed(() => {
      return store.getters.getUnderWriters;
    });
    // let underWriterList = ref({
    //   data: [],
    //   options: store.getters.getDomSalesAgent,
    //   placeholder: "Select Underwritter",
    //   searchable: true,
    //   createTag: true
    // });
    let policyCount = ref<any>({});
    let userStr: any;
    let userObj = ref<any>({});
    let childPolicyId = ref<any>({});
    let policyDocuments = ref<any>({});
    const getUnderwritterAgentList = () => {
      Promise.all([
        UserService.getDomSalesAgents({
          type: "Car",
          isArchived: true,
          isActive: false,
        }),
      ]).then((data) => {
      
        agentList = computed(() => {
          return store.getters.getDomSalesAgent;
        });
      });
      Promise.all([UserService.getUnderWriters()]).then((data) => {
        underWriterList = computed(() => {
          return store.getters.getUnderWriters;
        });
      });
    };

    const getActiveSalesAgentList = () => {
      
      UserService.getActiveDomSalesAgents({
          type: "Car",
          isArchived: true,
          isActive: true,
      });
      
    };

    onMounted(() => {

      // API Action to get payment types
      bodyParameters.value.keyword = "";

      if(['policy_completed', 'cancellation_completed', 'refund_completed'].includes(props.type)) {
        if(router.currentRoute.value.query && router.currentRoute.value.query.sf) {
          bodyParameters.value.keyword = router.currentRoute.value.query.sf.toString()
          bodyParameters.value.task_due_dates = 'all'
        } else {
          bodyParameters.value.start_date = backDate.value
          bodyParameters.value.end_date = presentDate.value

          value2.value = [backDate.value, presentDate.value]
        }
        
      }

      userStr = LocalStorageServices.getUser();
      userObj.value = JSON.parse(userStr);
      bodyParameters.value.agent = [1, 9].includes(userObj.value.role_id)
        ? "all"
        : "mine";
      getPolicyList(bodyParameters);

      getUnderwritterAgentList();
      getActiveSalesAgentList();
      Promise.all([MasterService.getPaymentTypes()]).then((data) => {
        const allData = {
          label: "All Transaction Type",
          value: "",
        };
        selectedPaymentType.value = "";
        let result = store.getters.getPaymentTypeList;
        result.unshift(allData);
        paymentTypes.value = result;
      });

      // MasterService.getCancellationReason();
      // MasterService.getReasonnReason();

      mainSocket
        .channel("policy-channel")
        .listen("CarPolicyCountSocketEvent", (response) => {
          // console.log("policy-channel", response);
          if (response.policy_status) {
            getPolicyList(bodyParameters, false);
          }
        });
      // /getPolicyCount
    });
    const permissions = computed(() => {
      return store.state.AuthModule.aclPermission;
    });
    const getPaginationUrl = (index) => {
      // let pagenationUrl = index.split("=");
      window.scrollTo({ top: 0, behavior: 'smooth' })
      bodyParameters.value.keyword = "";
      bodyParameters.value.index = currentPage.value;
      PolicyService.getPolicyList(bodyParameters.value);
    };
    const onPerPageChange = (event) => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      bodyParameters.value.per_page = event.target.value;
      PolicyService.getPolicyList(bodyParameters.value);
    };

    const getPolicyListPeriod = (listPeriod) => {
      if(listPeriod) {
        var startTS = new Date(listPeriod[0]);
        var endTS = new Date(listPeriod[1]);
        bodyParameters.value.start_date =
          startTS.getFullYear() +
          "-" +
          (startTS.getMonth() + 1) +
          "-" +
          startTS.getDate();
        bodyParameters.value.end_date =
          endTS.getFullYear() +
          "-" +
          (endTS.getMonth() + 1) +
          "-" +
          endTS.getDate();
      } else {
        bodyParameters.value.start_date = '';
        bodyParameters.value.end_date = '';
      }
      // bodyParameters.value.keyword = "";
      getPolicyList(bodyParameters);
    };

    let disableSaveButton = computed(() => {
      return store.getters.getDisableButtonStatus;
    });

    const search = ref<string>("");
    const searchPolicy = () => {
      // bodyParameters.value.keyword = search.value;
      getPolicyList(bodyParameters);
    };
    const selectedPaymentType = ref("");
    const resetBodyParam = () => {
      search.value = "";
      value2.value = [];
      activeLink.value = "all";

      let start_date = ''
      let end_date = ''
      if(['policy_completed', 'cancellation_completed', 'refund_completed'].includes(props.type)) {
        start_date = backDate.value
        end_date = presentDate.value

        value2.value = [backDate.value, presentDate.value]
      }

      bodyParameters.value = {
        status: props.status,
        per_page: "50",
        keyword: "",
        start_date,
        end_date,
        agent: "all",
        policy_sales_agent: "",
        underwriter: "",
        payment_type: "",
        task_due_dates: "",
        index: 1,
        type: props.type,
      };

     
      if (
        permissions.value.is_sales_agent ||
        permissions.value.is_underwritter ||
        permissions.value.is_team_leader
      ) {
        selectedPaymentType.value = "";
        bodyParameters.value.agent = "mine";
      }
      if (
        permissions.value.is_admin ||
        permissions.value.is_accountant ||
        permissions.value.is_manager
      ) {
        selectedPaymentType.value = "";
        bodyParameters.value.agent = "all";
      }

      getUnderwritterAgentList();
      getPolicyList(bodyParameters);
      // PolicyService.getPolicyList(bodyParameters);
    };
    const exportPolicyAssigned = () => {
      Promise.all([
        PolicyService.exportPolicyAssigned(bodyParameters.value),
      ]).then((data) => {
        let exportApproval = computed(() => {
          return store.getters.getExportApproval;
        });
        exportUrl.value = exportApproval.value;
      });
    };
    const showAction = () => {
      //
    };
    const getPolicyListFor = (status) => {
      bodyParameters.value.agent = status;
      getPolicyList(bodyParameters);
    };
    const getPolicyList = (bodyParameters, reload = true) => {
      currentPage.value = 1;
      bodyParameters.value.reload = reload
      Promise.all([
        PolicyService.getPolicyList(bodyParameters.value),
        PolicyService.getPolicyListCount(bodyParameters.value),
      ]).then((data) => {
        policyCount.value = store.getters.getPolicyCount;
      });
    };
    const getPolicyList2 = (bodyParameters) => {
      currentPage.value = 1;
      Promise.all([
        PolicyService.getPolicyList(bodyParameters),
        PolicyService.getPolicyListCount(bodyParameters),
      ]).then((data) => {
        policyCount.value = store.getters.getPolicyCount;
      });
    };

    policyCount = computed(() => {
      return store.getters.getPolicyCount;
    });
    let policyListObj = computed(() => {
      return store.getters.getpolicyList;
    });
    const getPaymentTypeFor = (paymentType) => {
      if (
        paymentType.target.value === "all" ||
        paymentType.target.value === "mine"
      ) {
        // bodyParameters.value.agent = paymentType.target.value;
        bodyParameters.value.payment_type = "";
      } else {
        // bodyParameters.value.agent = "";
      // alert(paymentType.target.value);
      // if(paymentType.target.value == 2) {
      //   bodyParameters.value.payment_type = [2, 6];
      // } else {
        bodyParameters.value.payment_type = paymentType.target.value;
      // }
      }

      // console.log(paymentType.target.value)
      getPolicyList(bodyParameters);
    };
    const getTaskListFor = (e) => {
      activeLink.value = e;
      bodyParameters.value.task_due_dates = e;
      getPolicyList(bodyParameters);
    };
    const onSalesAgentChange = (event) => {
      // bodyParameters.value.policy_sales_agent = event;
      getPolicyList(bodyParameters);
    };
    const onUnderwriterChange = (event) => {
      // bodyParameters.value.underwriter = event;
      getPolicyList(bodyParameters);
    };
    const resetSalesAgent = (event) => {
      bodyParameters.value.policy_sales_agent = event == null ? "" : event;
      bodyParameters.value.type = "";
      getPolicyList(bodyParameters);
    };
    const resetUnderWriter = (event) => {
      bodyParameters.value.underwriter = event == null ? "" : event;
      bodyParameters.value.type = "";
      getPolicyList(bodyParameters);
    };
    const TransferSaleModal = ref()
    const TransferUnderwriterRef = ref()
    const cancelPolicyModal = ref()
    const refundPolicyModal = ref()
    const editCancelModal = ref()
    const editCancelModalClaim = ref()
    let isClaimMadeView = ref(false)

    const sendPolicyId = (policy, category = "standard", isOpenModal = false, isClaimMade = false) => {
      isClaimMadeView.value = false
      TransferSaleModal.value.toFirst()
      TransferUnderwriterRef.value.toFirst()
      cancelPolicyModal.value.toFirst()
      refundPolicyModal.value.toFirst()
      if(category == 'cancel' && policy.cannot_cancel_reason != 'claim') {
        editCancelModal.value.toFirst()
      }
      PolicyService.resetTempDoc();
      childPolicyId.value = policy.id;
      PolicyService.resetPolicyDocumentValues([]);
      if (category == "cancel") {
        // MasterService.getDocumentTypes({
        //   categories: "cancellation",
        //   document_type: "",
        // });
        MasterService.getCanxDocumentTypes({
          categories: "cancellation",
          document_type: "",
        });

        MasterService.getCancellationReason();
      } else {
        MasterService.getReasonnReason();
      }
      Promise.all([
        PolicyService.getPolicyDetails({
          policy_id: childPolicyId.value,
        }),
      ]).then((data) => {
        setTimeout(() => {
          policyDetails = computed(() => {
            return store.getters.getpolicyDetails;
          });
          if(category == 'cancel' && policyDetails.value.cannot_cancel_reason && policyDetails.value.cannot_cancel_reason == 'claim' && !isClaimMade) {
            editCancelModalClaim.value.open()
            setPolicyClaim(policyDetails.value.car_lead_quote.quote_ref_no)
          } else {
            editCancelModal.value.setData();
            if (policyDetails.value.status > 4 || category == "cancel") {
              if(isOpenModal) {
                PolicyService.setCancelModal(true);
              }
              categories.value = category == "cancel" ? "cancellation" : category;
              let title = category == "policy" ? "Policy Certificate" : "";
              MasterService.getPayToMasters({});
              getCancellationDocument(childPolicyId.value);
              getPolicyDocuments(childPolicyId.value, title);
            } else {
              categories.value = category;
              let title = category == "policy" ? "Policy Certificate" : "";
              getPolicyDocuments(childPolicyId.value, title, policy.lead_id);
            }
            getTempDocument(childPolicyId.value, policy.lead_id, isClaimMade);
          }
          }, 100);
      });
    };
    const getPolicyDocuments = (policy_id, title = "", lead_id="") => {
      // PolicyService.getPolicyDocuments({
      if (lead_id == "") {
        PolicyService.getAllDocuments({
          policy_id: policy_id,
          title,
        }).then((data) => {
          policyDocuments.value = computed(() => {
            return store.getters.getPolicyDocs;
          });
        });
      } else {
        PolicyService.getAllDocuments({
          policy_id: policy_id,
          lead_id: lead_id,
          title,
        }).then((data) => {
          policyDocuments.value = computed(() => {
            return store.getters.getPolicyDocs;
          });
        });
      }
    };

    const getCancellationDocument = (policyId) => {
      Promise.all([
        PolicyService.getPolicyCancellationDocuments({
          policy_id: policyId,
          categories: categories.value,
        }),
      ]).then((data) => {
        cancellationDocs.value = computed(() => {
          return store.getters.getPolicyCancellationDocuments;
        });
      });
    };
    const getTempDocument = (policyId, leadId, isClaimMade = false) => {

      // if(categories.value == 'cancellation'){
          Promise.all([
            PolicyService.getTempPolicyCancelDocuments({
              policy_id: policyId,
              lead_id: leadId,
              categories: categories.value,
              is_cannot_cancel: isClaimMade ? true : false,
            }),
          ]).then((data) => {
            tempDocs.value = computed(() => {
              return store.getters.getTempPolicyCancelDocs;
            });
          });
      // }else{

      //   Promise.all([
      //     PolicyService.getTempPolicyDocuments({
      //       policy_id: policyId,
      //       lead_id: leadId,
      //       categories: categories.value,
      //     }),
      //   ]).then((data) => {
      //     tempDocs.value = computed(() => {
      //       return store.getters.getTempPolicyDocs;
      //     });
      //   });
      // }


    };
    const resetPolicyData = () => {
      setTimeout(() => {
        getPolicyList(bodyParameters);
      }, 1000);
    };
    const showPolicyListDom = computed(() => {
      return store.getters.getPolicyListDom;
    });

    const clearTemp = () => {
      PolicyService.resetTempDoc();
      categories.value = "";
      // console.log(tempDocs.value)
      // tempDocs.value = "";
    };

    const policyDocument = computed(() => {
      return store.getters.getPolicyDocs;
    });

    const paginatioObject = computed(() => {
      return store.getters.getPolicyPaginationObject;
    });

    const currentPage = ref(1);
    const activeLink = ref("all");
    const value2 = ref<any>([]);
    const loading = computed(() => {
      return store.getters.getPaLoading;
    });

    onBeforeRouteLeave(() => {
      ElNotification.closeAll();
      store.commit(Mutations.SET_PA_LOADING, true);
    });

    const load = async (row, treeNode: unknown, resolve) => {
      // console.log(row);
      // let parent = false;

      const result = await PolicyService.getChildInvoiceByPolicy({
        parent_invoice_id: row.car_lead_invoice ? row.car_lead_invoice.id : null,
      });
      resolve(result);
    };

    const formatDate = (value) => {
      return moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')
    }

    const formatDateRefund = (value) => {
      return moment(value, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
    }

    const viewReceipt = (invoice) => {
      window.open(invoice.invoiceDoc.file_path, "_blank");
    };

    const isViewCancellationDetailsCheck = (isOpenView = false) => {
      if(props.workFlow !== 'editCancellation') {
        isViewCancellation.value = isOpenView;
      }
    };

    const getCancelPolicyList = () => {
      getPolicyList(bodyParameters)
    };

    const getPolicyRefundList = () => {
      getPolicyList(bodyParameters)
    };

    const payToList = computed(() => {
      return store.getters.getPayToList;
    });
    
    const refundModal = computed(() => {
        return store.getters.getRefundModal;
    });

    const openCliamCancellationDocumentView = (rowPolicy, isClaimMade = false) => {
      sendPolicyId(rowPolicy, 'cancel', true, isClaimMade)
      isClaimMadeView.value = isClaimMade
    };

    return {
      isClaimMadeView,
      openCliamCancellationDocumentView,
      getPolicyList,
      editCancelModal,
      editCancelModalClaim,
      TransferSaleModal,
      cancelPolicyModal,
      refundPolicyModal,
      TransferUnderwriterRef,
      formatDate,
      formatDateRefund,
      load,
      customFunction,
      ElNotification,
      loading,
      value2,
      activeLink,
      paginatioObject,
      currentPage,
      policyDocument,
      categories,
      clearTemp,
      selectedPaymentType,
      permissions,
      getPolicyListFor,
      showAction,
      search,
      searchPolicy,
      onPerPageChange,
      getPaginationUrl,
      bodyParameters,
      getPolicyListPeriod,
      resetBodyParam,
      policyListObj,
      agentList,
      policyCount,
      underWriterList,
      getPaymentTypeFor,
      onUnderwriterChange,
      onSalesAgentChange,
      resetSalesAgent,
      resetUnderWriter,
      exportPolicyAssigned,
      disableSaveButton,
      exportUrl,
      sendPolicyId,
      childPolicyId,
      resetPolicyData,
      showPolicyListDom,
      policyDetails,
      tempDocs,
      cancellationDocs,
      paymentTypes,
      policyDocuments,
      getTaskListFor,
      getPolicyList2,
      viewReceipt,
      isViewCancellationDetailsCheck,
      isViewCancellation,
      getCancelPolicyList,
      payToList,
      refundModal,
      getPolicyRefundList,
    };
  },
});
